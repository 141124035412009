<script setup lang="ts">
const auth = useAuth();

onMounted(() => {
  try {
    if (window?.customerly?.t) {
      customerly.load({
        // User Data
        app_id: "53323704",
        user_id: auth.user.id,
        name: auth.user.first_name + " " + auth.user.last_name,
        email: auth.user.email,
        visible: false,

        // Plan and other custom attributes
        attributes: {
          phone: auth.user.phone,
          subdomain: auth.subdomain,
          company_name: auth?.user?.active_account?.company_name,
          company_id: auth?.user?.active_account_id,
        },

        // Account Details
        company: {
          company_id: auth?.user?.active_account_id,
          name: auth?.user?.active_account?.company_name,
          //Add any Company properties you might need to track here
          plan_id: auth?.user?.active_account?.plan_id,
          account_status: auth?.user?.active_account?.account_status,
          verified: auth?.user?.active_account?.verified,
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
  // try {
  //   window.$sleek.setUser({
  //     mail: auth?.user?.email,
  //     id: auth?.user?.id,
  //     name: auth?.user?.first_name + " " + auth?.user?.last_name,
  //   });
  //   window.$sleek.addMetadata({
  //     phone: auth?.user?.phone,
  //     subdomain: auth?.subdomain,
  //     company_name: auth?.user?.active_account?.company_name,
  //     company_id: auth?.user?.active_account_id,
  //   });
  // } catch (e) {
  //   console.log(e);
  // }
});

onMounted(() => {
  window.addEventListener(
    "sleek:init",
    () => {
      //@ts-ignore
      window.$sleek.setUser({
        mail: auth?.user?.email,
        id: auth?.user?.id,
        name: auth?.user?.first_name + " " + auth?.user?.last_name,
      });
      //@ts-ignore
      window.$sleek.addMetadata({
        phone: auth?.user?.phone,
        subdomain: auth?.subdomain,
        company_name: auth?.user?.active_account?.company_name,
        company_id: auth?.user?.active_account_id,
      });
    },
    false,
  );
});
</script>

<template>
  <Teleport to="body">
    <div class="customerly"></div>
  </Teleport>
</template>
